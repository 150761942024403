import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="home-footer">
      <p>&copy; 2024 Mi Sitio Web. Todos los derechos reservados.</p>
    </footer>
  );
}

export default Footer;